<script lang="ts">
import { reactiveComputed, useEventBus } from '@vueuse/core'
import { intersection } from 'lodash-es'
import { useQueryClient } from '@tanstack/vue-query'
import { useB2cBuyMachine } from '../machine/useB2cBuyMachine'
import AutomaticPopup from './AutomaticPopup.vue'
import { useUserInfo } from '~/composables/app'
import { useRentDuration } from '~/composables/tron'
import { useAppState } from '~/composables/useAppState'
import { tron } from '~/utils/tron-v2'
import { resourcePriceRange, useCalculation } from '~/composables/app/getB2cAmountCalculator'
import useHideFormHelp from '~/composables/useHideFormHelp'
import { useComponentRef } from '~/composables/vue'
import { useAvailableEnergy } from '~/composables/app/useAvailableEnergy'
import { imtoken } from '~/utils/imtoken'

export const CONFIRM_INFO_KEY = Symbol('confirmInfo')

function useAccountAddress() {
  const address = ref('')
  const { account } = tronComp.useAccountCurrent()

  watchEffect(() => {
    if (!address.value && account.address)
      address.value = account.address
  })

  return address
}
</script>

<script setup lang="ts">
const queryClient = useQueryClient()
const { machine } = useB2cBuyMachine()!
const rentDurationMap = useRentDuration()
const { config, usableEnergy } = useAppState()
const { account: tronAccount, isConnected } = tronComp.useAccountCurrent()
const { rentableData, rentableColumns, deilColumns, deilData } = useAvailableEnergy()
const setType = ref('add')
const rentResLimit = reactiveComputed(() => ({
  c2cMaxRes: config.value?.order.energy_c2c_max_value ?? 1_000_000,
  c2cMinRes: config.value?.order.energy_c2c_min_value ?? 1_000_000,
}))
const maxEnergy = computed(() => Math.max(...Object.values(usableEnergy.value?.energy_available ?? {})))
const smallAmount = computed(() => buyForm.formState.resourceValue <= 100000 && imtoken.isImToken())
const PaymentProcess = defineAsyncComponent(
  () => import('./PaymentProcessPopup.vue'),
)
const autoMarker = ref(true)
const isAutoCancel = ref(false)
const unitPriceRange = resourcePriceRange(autoMarker)
const paymentProcessExpose = useComponentRef(PaymentProcess)
// 价格范围
const address = useAccountAddress()
const buyForm = useAntFormValidator({
  resourceValue: {
    value: ref(''),
    validator(rule, value) {
      if (typeof value !== 'number')
        return promiseError($t('inMpjyyhk3Mpf71peuqz'))

      if (value < unitPriceRange.minRentable)
        return promiseError(`${$t('82J5xijlLyebnP0mOha')}  ${unitPriceRange.minRentable} ${$t('YUG0RoDj9Kcw8PtVRPR9Y')}`)

      const maxRentable = toRef(unitPriceRange, 'maxRentable')

      if (maxRentable.value === (rentResLimit.c2cMaxRes ?? 0) && maxRentable.value > (rentResLimit.c2cMaxRes ?? 0))
        return Promise.resolve()

      if (maxRentable.value > 0 && value > maxRentable.value)
        return promiseError(`${$t('ztxPrjv9GdeaZtGat9R8a')} ${maxRentable.value} ${$t('YUG0RoDj9Kcw8PtVRPR9Y')}`)

      return Promise.resolve()
    },
  },
  address: {
    value: address,
    validator(rule, value) {
      if (!!tron.tronWeb && !tron.isAddress(value))
        return promiseError($t('h9cXmdNxAhS3vVl3LyFj'))

      return Promise.resolve()
    },
  },
  rentDuration: {
    value: toRef(rentDurationMap, 'duration'),
    validator(rule, value) {
      if (typeof value !== 'number')
        return Promise.reject($t('ojnO7gOn5bBgUVpOzx3xy'))

      if (value % 1 !== 0)
        return Promise.reject($t('ly48hQBwarLWy6Bk9Qy8y'))

      if (rentDurationMap.unit === 'd') {
        if (value < 1 || value > 30)
          return Promise.reject($t('OMQRqRZC4EdQNtRmoIOU5'))
      }

      if (rentDurationMap.unit === 'h') {
        if (value !== 1 && value !== 3)
          return Promise.reject($t('OMQRqRZC4EdQNtRmoIOU5'))
      }

      if (smallAmount.value && rentDurationMap.unit === 'd')
        return Promise.reject($t('OMQRqRZC4EdQNtRmoIOU6'))

      return Promise.resolve()
    },
  },
  receiveAddress: {
    value: ref(''),
    validator(rule, value) {
      if (!tronAccount.address && !value)
        return promiseError($t('zusWshvItgohoPIbWpV0K'))

      if (tron.tronWeb) {
        if (
          (!tronAccount.address && !tron.isAddress(value))
          || (value && !tron.isAddress(value))
        )
          return promiseError($t('h9cXmdNxAhS3vVl3LyFj'))
      }

      return Promise.resolve()
    },
  },
  rentTimeSecond: {
    value: ref(3600),
    validator(rule, value) {
      if (value <= 0)
        return promiseError($t('dHgidzfd5qiruU6fTpsQ'))

      return Promise.resolve()
    },
  },
  waitSecond: {
    value: ref(1800),
  },
})
const { target, checking } = useHideFormHelp(buyForm.$form)
const confirmInfo = reactive({
  receiveAddress: '',
  resourceValue: 0,
  rentDuration: 0,
  rentTimeSecond: 3600,
  rentDurUnit: '',
  amount: 0,
  autoToMarket: 1,
  orderNo: '',
  orderType: 1,
  payAddress: tronAccount.address,
  paySymbol: 'TRX',
  payTxId: '',
  resourceType: 1,
  waitSecond: 1800,
})

async function validate() {
  return new Promise((resolve, reject) => {
    if (!calculationData.value.amount) {
      aMessage.error($t('q4efE6x2sPduKu3JyTslF'))
      reject(new Error(''))
    }

    if (!config) {
      aMessage.error($t('qln6Bk0oqlzAbSHr2kc'))
      reject(new Error(''))
    }

    resolve(true)
  })
}

async function submit() {
  const formState = (await buyForm.$form.value
    ?.validate()
    .catch(() => {
      checking.setTrue()
    })) as typeof buyForm.formState

  if (!formState)
    return

  await validate()
  // 接收地址
  confirmInfo.receiveAddress = formState.receiveAddress || tronAccount.address
  confirmInfo.resourceValue = formState.resourceValue
  confirmInfo.rentTimeSecond = buyForm.formState.rentTimeSecond
  confirmInfo.rentDuration = rentDurationMap.duration
  confirmInfo.rentDurUnit = rentDurationMap.unit
  confirmInfo.amount = toDecimalPlaces(calculationData.value.amount, 6)
  confirmInfo.autoToMarket = autoMarker.value ? 1 : 0
  confirmInfo.waitSecond = isAutoCancel.value ? buyForm.formState.waitSecond : 0
  machine.send('OPEN')
}

function moreDurationChange(data: { dur: number; unit: 'h' | 'd' }) {
  rentDurationMap.duration = data.dur
  rentDurationMap.unit = data.unit
  buyForm.$form.value?.validate('rentDuration', { triggerName: 'change' }).catch(() => {})
}

/* b2c购买能量 */
const calculationData = computed(() => {
  return useCalculation({
    resourceValue: buyForm.formState.resourceValue,
    rentDuration: rentDurationMap.duration,
    rentTimeUnit: rentDurationMap.unit,
  })
})

const otherMode = computed(() => tron.tronWeb && isConnected)

const showCheckBox = computed(() => {
  const [c2cTimeRange, b2cTimeRange] = [config?.value?.order?.time_range_c2c, config?.value?.order?.time_range_b2c]

  const union = intersection(toValue(c2cTimeRange), toValue(b2cTimeRange))
  if (
    union.includes(toValue(buyForm.formState.rentTimeSecond))
    && buyForm.formState.resourceValue >= rentResLimit.c2cMinRes
    && buyForm.formState.resourceValue <= rentResLimit.c2cMaxRes) {
    autoMarker.value = true
    return true
  }
  else {
    autoMarker.value = false
    return false
  }
})

const isPenNumber = ref(false)
function changeEnergyUnit(e: boolean) {
  isPenNumber.value = e
}

const stopWatchAddress = watch(() => tronAccount.address, (abb) => {
  if (abb) {
    buyForm.formState.receiveAddress = abb
    setTimeout(() => stopWatchAddress())
  }
}, { immediate: true })

const bus = useEventBus<string>('SWITCH-WALLET')
const autoPopup = useEventBus<string>('AUTO-POPUP')

bus.on(() => {
  buyForm.formState.receiveAddress = tronAccount.address
})

provide(CONFIRM_INFO_KEY, confirmInfo)

// 检查登录状态start
const { hasLogged } = useUserInfo()
const LoginConfirmPopup = defineAsyncComponent(
  () => import('./LoginConfirmPopup.vue'),
)
const showLoginConfirmPopup = ref(false)

function toLogin() {
  globalModal.AuthModal?.openLogin?.(
    {
      loginSuccessCallback() {
        isAutoPopup.value = true
      },
    },
  )
}

const isAutoPopup = ref(false)
function checkLoginStatus() {
  if (!hasLogged.value)
    showLoginConfirmPopup.value = true
  else isAutoPopup.value = true
}
// 检查登录状态end

function updateDate() {
  isAutoPopup.value = false
  queryClient.invalidateQueries({
    queryKey: [apis.cronAddressList.id],
    exact: false,
  })
  autoPopup.emit()
}
</script>

<template>
  <div class="gradient-border-box py-14 px-12 w-142 rounded-8! border-3! lt-tabletl:w-auto lt-tabletl:rounded-4! lt-tabletl:px-4 lt-tabletl:py-7">
    <div class="text-text text-7 flex-between font-bold text-center mb-6 lt-tabletl:text-7">
      <div>{{ $t("Zzq0JBCy9Xu4fzFLOIQEF") }}</div>
      <button class="receive-btn" data-test-id="85r7" @click="checkLoginStatus">
        <i class="i-local:add-btn text-20px mr-3px" />
        {{ $t('sAxJMtusGjvvozgNadkCo') }}
      </button>
    </div>

    <div class="">
      <AForm
        layout="vertical"
        v-bind="buyForm.formProps"
        :size="$bp.ltTabletl ? 'small' : undefined"
        class="antd-cover__bubble-help-form bubble-help-top"
      >
        <!-- 接收方地址 -->
        <AFormItem v-bind="buyForm.formItemProps.receiveAddress" class="ant-cover__basic-form-item [--bubble-offset:60px]">
          <template #label>
            <div class="flex justify-between w-full">
              <span>{{ $t("SMmd46cyjpYFJ9oPU4tuB") }}</span>
              <!-- 显示连接钱包按钮 -->
              <div
                v-if="!isConnected"
                class="flex justify-center items-center cursor-pointer text-#148AF5 hover:text-text"
                data-test-id="9ev7"
                @click="globalModal.ConnectWallet?.open()"
              >
                <i class="i-local:wallet-s3 text-[20px] mr-1" />
                <span>{{ $t("tLprg_biKgG1SBuqQghN-") }}</span>
              </div>
            </div>
          </template>
          <AInput
            v-model:value="buyForm.formState.receiveAddress"
            :placeholder="$t('-FKV-KA_vu53AswcJ3Qwb')"
            autocomplete="off"
            allowClear
            data-test-id="QkQ4"
          >
            <template #prefix>
              <i class="i-local:tron-wallet-s2 text-1.25em" />
            </template>
            <template #clearIcon>
              <i class="i-ant-design:close-circle-outlined text-primary text-1.25em" />
            </template>
          </AInput>
        </AFormItem>

        <!-- 租用量 -->
        <AFormItem v-bind="buyForm.formItemProps.resourceValue" class="ant-cover__basic-form-item [--bubble-offset:100px]">
          <template #label>
            <div class="flex justify-between w-full">
              <div class="flex items-center">
                <span class="lt-mobile:text-16px">{{ isPenNumber ? $t('exnibzgWBvKuAc4PmIno') : $t('2rIwE5lzikM04jB7M1vbR') }}</span>
                <ATooltip>
                  <template #title>{{ isPenNumber ? $t('56KKf1NCm92oPaMm09fhx') : $t('dzGehsFax9sdGoUEy5jx') }}</template>
                  <i class="i-ant-design:question-circle-outlined ml1 text-[1.2em] text-[#8098bf]" />
                </ATooltip>
              </div>
              <TransferCountHint
                v-if="buyForm.formState.resourceValue"
                :rentResValue="buyForm.formState.resourceValue"
                :rentDur="rentDurationMap.duration"
                :durUnit="rentDurationMap.unit"
                :haveTokenEnergyUsed="config?.estimate.have_token_energy_used"
                :notHaveTokenEnergyUsed="config?.estimate.not_have_token_energy_used"
              />
            </div>
          </template>
          <RemtaDuration
            v-model="buyForm.formState.resourceValue"
            :shortcut="config.order.energy_b2c_quick_options"
            :min="unitPriceRange.minRentable"
            :max="unitPriceRange.maxRentable === 0 ? '' : unitPriceRange.maxRentable"
            @changeEnergyUnit="changeEnergyUnit"
            @onShortcutChange="buyForm.$form.value?.validate('resourceValue', { triggerName: 'change' }).catch(() => {})"
          />
        </AFormItem>

        <div class="mt--2.5 mb-2.5 text-[#8098BF] lt-tabletl:text-4">
          <div>
            <span>{{ $t("KIbE3pAp80b2m7Wwqf9ye") }}</span>
            <span>
              {{ formatAmount(maxEnergy, 0) }}
              <template v-if="$bp.tabletl">
                Energy
              </template>
              <template v-else>
                <AvailablePopover
                  :title="$t('mlhc829WjrAXwBEZdSw')"
                  :columns="rentableColumns"
                  :dataSource="rentableData"
                />
              </template>
            </span>
          </div>
          <div>
            <span>{{ $t("tosUM-DG_T6duJ3EsxMN5") }}</span>
            <span>{{ formatAmount(usableEnergy?.freed_energy, 0) }}
              <template v-if="$bp.tabletl">
                Energy
              </template>
              <template v-else>
                <AvailablePopover
                  :title="$t('ysILvWajcBBsMaUnyucNG')"
                  :columns="deilColumns"
                  :dataSource="deilData"
                />
              </template>
            </span>
          </div>
        </div>

        <!-- 租用时间 -->
        <AFormItem
          class="ant-cover__basic-form-item  [--bubble-offset:105px]"
          v-bind="buyForm.formItemProps.rentDuration"
          :label="$t('VDbiV35z7tQmGihnu5xza')"
        >
          <MoreDuration
            v-model:rentDuration="buyForm.formState.rentDuration"
            v-model:rentTimeSecond="buyForm.formState.rentTimeSecond"
            :rentResValue="buyForm.formState.resourceValue"
            mode="b2c"
            @onChange="moreDurationChange"
          />
        </AFormItem>
      </AForm>

      <!-- 支付信息 -->
      <div v-if="buyForm.formState.resourceValue && buyForm.formState.rentDuration" class="pay-panel lh-normal">


        <div class="text-[16px]  mb2 text-[#e2e8f2]">{{ $t('QWNBNFCpECWKnkEbwzClt') }}</div>
        <div class="text-primary mt-[10px] bg-[#202C52] rounded-[10px] pt-[10px] pb-[8px] px-[10px]">
          <div>
            <div class="flex-between mb-[6px]">
              <span>{{ $t('MdLWMNeCJsOrXnrrrWLgX') }}：</span>
              <p class="text-md">
                {{ formatAmount(buyForm.formState.resourceValue, 0) }} {{ $t('A5FX7Ej5VR2oi-ftUBpb2') }},
                {{ buyForm.formState.rentDuration }}
                <span v-if="buyForm.formState.rentDuration === 1">{{ rentDurationMap.unit.replace('h', $t('SlBOLvhC-SbNV2HiN7_bi')).replace('d', $t('sgq3K4HMvzvjpCGigTbMu')) }}</span>
                <span v-else>{{ rentDurationMap.unit.replace('h', $t('GRAxQVNTjLwXYfOpY6FRh')).replace('d', $t('8zE4c0R_g37KkcLgrn4u0')) }}</span>
              </p>
            </div>
            <div class="flex justify-between items-baseline">
              <span>{{ $t('eZ4sG6S0vn3U5mdh1etvj') }}：</span>
              <span>

                <span class="text-md line-through"> {{ formatAmount(calculationData.originalPrice, 6) }}  TRX</span>
                <span class="text-text-green text-2xl ml2 font-bold lt-tabletl:text-15px">{{ formatAmount(calculationData.amount, 6) }} TRX </span>
              </span>
            </div>
          </div>
        </div>
        <WaitTips class="mt-2" v-model:value="buyForm.formState.waitSecond" v-model:isAutoCancel="isAutoCancel" />
        <div v-show="calculationData.amount !== 0" class="text-sm text-text-green mt2">
          <div class="text-sm lt-tabletl:text-xs">
            <span v-if="calculationData.serviceAmount">{{ $t('7Eo0l9AzM3ddXkWvyOxO', {
              num: calculationData.tSun,
              tip: calculationData.serviceAmount,
              time: rentDurationMap.duration,
              type: rentDurationMap.unit.replace("h", rentDurationMap.duration === 1 ? $t("SlBOLvhC-SbNV2HiN7_bi") : $t('GRAxQVNTjLwXYfOpY6FRh')).replace("d", rentDurationMap.duration === 1 ? $t("sgq3K4HMvzvjpCGigTbMu") : $t('8zE4c0R_g37KkcLgrn4u0')),
              percentage: calculationData.discount,
              count: formatAmount(calculationData.saveTRX, 2),
            }) }}</span>
            <span v-else>{{ $t('keZbTEoqWh1nz65_4Mkj', {
              num: calculationData.tSun,
              time: rentDurationMap.duration,
              type: rentDurationMap.unit.replace("h", rentDurationMap.duration === 1 ? $t("SlBOLvhC-SbNV2HiN7_bi") : $t('GRAxQVNTjLwXYfOpY6FRh')).replace("d", rentDurationMap.duration === 1 ? $t("sgq3K4HMvzvjpCGigTbMu") : $t('8zE4c0R_g37KkcLgrn4u0')),
              percentage: calculationData.discount,
              count: formatAmount(calculationData.saveTRX, 2),
            }) }}</span>
          </div>
        </div>
      </div>

      <!-- 支付 -->
      <div class="footer">
        <div>
          <Transition name="slide-fade">
            <template v-if="showCheckBox">
              <div class="checkbox text-[#8098bf] text-base my-[10px] lt-tabletl:text-sm">* {{ $t("Wp4QTTbgSk4JQCVPSe37o") }} </div>
            </template>
          </Transition>
          <AButton ref="target" class="comfirm-btn" block data-test-id="kjc1" @click="submit()">
            {{ $t("QWNBNFCpECWKnkEbwzClt") }}
          </AButton>
        </div>
      </div>

      <PaymentProcess :ref="paymentProcessExpose.$comp" />
    </div>

    <LoginConfirmPopup v-model="showLoginConfirmPopup" @confirm="toLogin" />
    <!-- 自动租赁 -->
    <AModal
      v-model:open="isAutoPopup"
      destroyOnClose
      wrapClassName="ant-cover__basic-modal"
      :footer="null"
      :maskClosable="false"
      width="540px"
      @cancel="machine.send('CLOSE')"
    >
      <AutomaticPopup v-model:setType="setType" :successFunc="updateDate" />
    </AModal>
  </div>
</template>

<style lang="scss" scoped>
// Transition 组件的过度样式
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(20px);
}

.receive-btn{
  --uno: 'lt-mobile:text-12px';

  display: flex;
  align-items: center;
  height: 34px;
  font-size: 16px;
  cursor: pointer;
  background: #266ef1;
  border: none;
  border-radius: 6px;

  &:hover{
    background: linear-gradient(90deg,#2680EB,#00C1A5) no-repeat;
  }
}
</style>
